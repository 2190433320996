import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { loadMessages, locale } from 'devextreme/localization';

import { SetDisplayDateFormat } from '@stores-actions/authentication.action';
import { AuthState } from '@stores-states/authentication.state';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';

export const devExtremLocal: any = {
  'en-GB': 'en-GB',
  en: 'en-US',
  fr: 'fr-CA',
  de: 'de-DE',
  es: 'es-ES',
  zh: 'zh-CN',
  ar: 'en-CA',
  ru: 'ru-RU',
  fi: 'fi-FI',
  da: 'en-CA',
  pt: 'pt-PT',
  sv: 'sv-SV',
};

@Injectable({
  providedIn: 'root',
})
export class I18nSetLocal {
  //available language for DevExtreme
  //https://github.com/DevExpress/DevExtreme/tree/20_2/js/localization/messages

  //https://en.wikipedia.org/wiki/Date_format_by_country
  shortBigEndian = 'yyyy M d';
  mediumBigEndian = 'yyyy MMM d';
  longBigEndian = 'yyyy MMMM d';
  fullBigEndian = 'yyyy MMMM d dddd';

  shortLittleEndian = 'dd MM yyyy';
  mediumLittleEndian = 'd MMM yyyy';
  longLittleEndian = 'd MMMM yyyy';
  fullLittleEndian = 'dddd d MMMM yyyy';

  shortMiddleEndian = 'M d, yy';
  mediumMiddleEndian = 'MMM d, yy';
  longMiddleEndian = 'MMMM d, yyyy';
  fullMiddleEndian = 'dddd MMMM d, yyyy';

  bigEndian = {
    short: this.shortBigEndian,
    medium: this.mediumBigEndian,
    long: this.longBigEndian,
    full: this.fullBigEndian,
  };

  littleEndian = {
    short: this.shortLittleEndian,
    medium: this.mediumLittleEndian,
    long: this.longLittleEndian,
    full: this.fullLittleEndian,
  };

  middleEndian = {
    short: this.shortMiddleEndian,
    medium: this.mediumMiddleEndian,
    long: this.longMiddleEndian,
    full: this.fullMiddleEndian,
  };

  displayLocal: any = {
    'en-GB': this.littleEndian,
    en: this.middleEndian,
    fr: this.littleEndian,
    es: this.littleEndian,
    zh: this.bigEndian,
    ar: this.middleEndian,
    ru: this.middleEndian,
    fi: this.middleEndian,
    da: this.middleEndian,
    pt: this.littleEndian,
    sv: this.middleEndian,
    de: this.middleEndian,
  };

  constructor(
    private translocoService: TranslocoService,
    private readonly store: Store,
    private service: TranslocoLocaleService
  ) {}

  setLocalBySession(): void {
    const session = this.store.selectSnapshot(AuthState.session);
    const language = session?.language ? session.language : navigator.language;
    this.setLocalBy(language);
  }

  setLocalBy(language: string): void {
    try {
      this.service.setLocale(language);
      this.translocoService.setActiveLang(language.slice(0, 2));

      // TODO - Work around for Danish. Need as proper implementation.
      if (language === 'da')
        import(
          '../../../../../node_modules/devextreme/localization/messages/en.json'
        ).then((lang) => {
          loadMessages(lang.default);
          locale(devExtremLocal[language.slice(0, 2)]);
        });
      else
        import(
          '../../../../../node_modules/devextreme/localization/messages/' +
            language.slice(0, 2) +
            '.json'
        ).then((lang) => {
          loadMessages(lang.default);
          locale(devExtremLocal[language.slice(0, 2)]);
        });

      this.service.setLocale(language);

      if (language !== 'en-GB') {
        language = language.slice(0, 2);
      }

      this.store.dispatch(
        new SetDisplayDateFormat(this.displayLocal[language]['short'])
      );
    } catch (e) {
      console.error(
        'Error setting locale: ' + e + ' - Unsupported - setting to en-US'
      );
      language = 'en-US';
    }
  }
}
